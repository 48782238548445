export const QS_WINDOW_WIDTH_THRESHOLD = 1130;

// Analytics Events Consts
export const CONFLUENCE_ONBOARDING_QUICKSTART = 'confluenceOnboardingQuickstart';
export const CONFLUENCE_ONBOARDING_QUICKSTART_DRAWER = 'confluenceOnboardingQuickstartDrawer';
export const CONFLUENCE_ONBOARDING_QUICKSTART_BUTTON = 'confluenceOnboardingQuickstartButton';
export const CONFLUENCE_ONBOARDING_QUICKSTART_BUTTON_DRAWER =
	'confluenceOnboardingQuickstartButtonDrawer';
export const CONFLUENCE_ONBOARDING_QUICKSTART_CTA = 'confluenceOnboardingQuickstartCta';
export const CONFLUENCE_ONBOARDING_QUICKSTART_CAROUSEL_ACTION =
	'confluenceOnboardingQuickstartCarouselAction';

// Quickstart Video Links
export const WELCOME_TO_CONFLUENCE_VIDEOS = {
	en: 'https://player.vimeo.com/progressive_redirect/playback/676035490/rendition/1080p?loc=external&signature=1ce9207d5a6a8957d85834336f59c42c45b099c8320d3c60cdbfe2f526136ec2',
	jp: 'https://player.vimeo.com/progressive_redirect/playback/738398214/rendition/1080p/file.mp4?loc=external&signature=b1d49a7d7a517d7d245e0d4018b4f4dba97d97c481ced019807dc97fa45631db',
	de: 'https://player.vimeo.com/progressive_redirect/playback/738398180/rendition/1080p/file.mp4?loc=external&signature=29b5a66a0b5a5bc2ef65c6d11048328650ae4c963b29877ac9ab5abac47ef4a4',
	cn: 'https://player.vimeo.com/progressive_redirect/playback/738398158/rendition/1080p/file.mp4?loc=external&signature=3f0f01307ffd26e49240387deb9b703d45cf59359b862dd8d1b68542c5d35166',
	br: 'https://player.vimeo.com/progressive_redirect/playback/738398131/rendition/1080p/file.mp4?loc=external&signature=36299800fdbeefd42b2da49a887e804dbb9c2df500acaab07627f8d01521770f',
	ru: 'https://player.vimeo.com/progressive_redirect/playback/738398112/rendition/1080p/file.mp4?loc=external&signature=26c0364851f5cb0d38d4396e5067473d825e8e633fd0cc204982fd3773f235b2',
	es: 'https://player.vimeo.com/progressive_redirect/playback/738398078/rendition/1080p/file.mp4?loc=external&signature=596aa393f72a91d1485f7b36edf5a9e03e25a1350a923a870181c7cd1e92c21c',
	nl: 'https://player.vimeo.com/progressive_redirect/playback/738398054/rendition/1080p/file.mp4?loc=external&signature=3927a84841c8b9bc1812797a72885a30c45c1749f661bbd15c01f09b067b5915',
	kr: 'https://player.vimeo.com/progressive_redirect/playback/738398022/rendition/1080p/file.mp4?loc=external&signature=ab4e580c57f4f9ca990f1cd917a55a43ad0ed3f3c87cdc3e5592634eb5a6b213',
	pl: 'https://player.vimeo.com/progressive_redirect/playback/738397991/rendition/1080p/file.mp4?loc=external&signature=869e4a87dc2f8d5bd49843d6066c8370dd3f1ca0a2a41e7d9334c9b63bab3d07',
	it: 'https://player.vimeo.com/progressive_redirect/playback/738397952/rendition/1080p/file.mp4?loc=external&signature=1b7a167fff2b5c656f349ed89aac9c88a9224329d7c68835c76e519a7a5b713b',
	fr: 'https://player.vimeo.com/progressive_redirect/playback/738397906/rendition/1080p/file.mp4?loc=external&signature=41b38194e902626c4a55d6ca7ec5ab4e267b395be78bcd15f5f1d0e86b083081',
};
