import gql from 'graphql-tag';

export const BreadcrumbsQuery = gql`
	query BreadcrumbsQuery(
		$spaceKey: String!
		$contentId: ID
		$skipContent: Boolean!
		$includeAlias: Boolean = false
	) {
		spaces(spaceKey: $spaceKey, first: 10) {
			nodes {
				alias @include(if: $includeAlias)
				id
				name
				homepage {
					id
				}
			}
		}
		content(id: $contentId, status: ["current", "draft", "archived"]) @skip(if: $skipContent) {
			nodes {
				id
				type
				status
				title
				ancestors {
					id
					type
					title
					status
				}
			}
		}
	}
`;
