export enum ExperienceTimeout {
	DEFAULT = 60000,

	CREATE_CONTENT = 30000,
	CREATE_DIALOG_LOAD = DEFAULT,
	MACRO_LOAD = 60000,

	EDITOR_LOAD = 25000,
	EDITOR_PUBLISH = DEFAULT,

	TEMPLATE_EDITOR_LOAD = 90000,
	TEMPLATE_EDITOR_PUBLISH = DEFAULT,

	IN_EDITOR_TEMPLATE_PANEL_LOAD = 20000,

	IN_EDITOR_TEMPLATES_UPDATE_CONTENT_REFRESH_EDITOR = 90000,

	HOME = 20000,

	NAVIGATION_LOAD = 30000,

	PAGE_LOAD = DEFAULT,

	SPACE_DIRECTORY = DEFAULT,

	SPACE_VIEWS = 20000,

	TEMPLATE_GALLERY_TEMPLATE_DETAIL_UPDATE = 20000,

	TEMPLATE_PREVIEW = 20000,

	TEMPLATE_SUGGESTIONS = 20000,

	LOOM_PAGE_HEADER_CONTENT_INSERTION = 20000,
}
