import type { FC } from 'react';
import React from 'react';

import type { ErrorBoundaryProps } from './ErrorBoundary';
import { ErrorBoundary } from './ErrorBoundary';
import type { TransparentErrorBoundaryProps } from './TransparentErrorBoundary';
import { TransparentErrorBoundary } from './TransparentErrorBoundary';

export function withErrorBoundary(errorBoundaryProps: ErrorBoundaryProps) {
	return withTypedErrorBoundary(ErrorBoundary, errorBoundaryProps);
}

export function withTransparentErrorBoundary(
	transparentErrorBoundaryProps: TransparentErrorBoundaryProps,
) {
	return withTypedErrorBoundary(TransparentErrorBoundary, transparentErrorBoundaryProps);
}

function withTypedErrorBoundary<BoundaryPropsT>(
	BoundaryComponent: React.ComponentType<BoundaryPropsT>,
	boundaryProps: BoundaryPropsT,
) {
	return function <T extends {}>(WrappedComponent: React.ComponentType<T>): FC<T> {
		return (wrappedProps: T) => (
			<BoundaryComponent {...boundaryProps}>
				<WrappedComponent {...wrappedProps} />
			</BoundaryComponent>
		);
	};
}
