import React from 'react';

import { LoadableBackground } from '@confluence/loadable';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

const ImpersonationFlagV2Loader = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ImpersonationFlag" */ './ImpersonationFlag'))
			.ImpersonationFlag,
});

const LazyImpersonationFlagComponent = () => <ImpersonationFlagV2Loader />;

export const LazyImpersonationFlag = withTransparentErrorBoundary({
	attribution: Attribution.PERMISSIONS_EXPERIENCE,
})(LazyImpersonationFlagComponent);
