import { useContext } from 'react';

import { SPAViewContext } from '@confluence/spa-view-context';

/**
 * @deprecated use SpaViewContext to access isSiteAdmin value instead
 * @returns
 */
export const useIsSiteAdmin = () => {
	const { isSiteAdmin, loading, error } = useContext(SPAViewContext);

	return {
		isSiteAdmin: isSiteAdmin && !loading && !error,
	};
};
