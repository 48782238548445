import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import type { ApolloClient } from 'apollo-client';

import { markErrorAsHandled } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';

import { isExpectedError } from '../../utils/isExpectedError';

import { getOnboardingStateGQL, setOnboardingStateGQL } from './OnboardingStatus.graphql';
import type {
	OnboardingStateInput,
	SetOnboardingState,
	SetOnboardingStateVariables,
} from './__types__/setOnboardingStateGQL';
import type {
	GetOnboardingState,
	GetOnboardingStateVariables,
} from './__types__/getOnboardingStateGQL';

export const deserializeState = (data: GetOnboardingState | null | undefined) => {
	const state = {} as any;
	// new graphQL call returns onboardingState array
	const { onboardingState } = data || {};

	// parse data and populate state accordingly
	if (onboardingState !== undefined) {
		onboardingState.forEach(({ key, value }) => {
			// key comes back as "quickstart-state:{property}" format
			// split by ':' to get parsed {property}
			// some keys don't have a prefix, which is why we have the ||
			const parsedKey = key.split(':')[1] || key;
			if (!value) {
				return;
			}
			if (/(true|false)/g.test(value)) {
				state[parsedKey] = value === 'true';
			} else {
				state[parsedKey] = value;
			}
		});
	}
	return state;
};

export const useOnboardingState = () => {
	const [setState, { error, called }] = useMutation<
		SetOnboardingState,
		SetOnboardingStateVariables
	>(setOnboardingStateGQL, {
		update(cache, { data }) {
			const states = data!.setOnboardingState;
			states.forEach(({ key, value }) => {
				// writeFragment can only handle a single data item at a time
				cache.writeFragment({
					id: `OnboardingState:${key}`,
					fragment: gql`
						fragment myKey on OnboardingState {
							key
							value
						}
					`,
					data: {
						key,
						value,
						__typename: 'OnboardingState',
					},
				});
			});
		},
		onError: (error) => {
			if (isExpectedError(error)) {
				markErrorAsHandled(error);
			}
		},
	});

	type SetOnboardingStateArgs = OnboardingStateInput | OnboardingStateInput[];

	const setOnboardingState = useCallback(
		(newOnboardingState: SetOnboardingStateArgs) => {
			let states: OnboardingStateInput[];
			if (Array.isArray(newOnboardingState)) {
				// supports batch updates
				states = newOnboardingState;
			} else {
				// backwards compability for single key-value pair
				states = [newOnboardingState];
			}

			void setState({
				variables: {
					states,
				},
				optimisticResponse: {
					setOnboardingState: states.map(({ key, value }) => ({
						key,
						value,
						__typename: 'OnboardingState',
					})),
				},
			});
		},
		[setState],
	);

	return { setOnboardingState, error, called };
};

export const useGetOnboardingState = (key: string | string[], skip = false) => {
	const { isLoggedIn, isLicensed } = useSessionData();

	const isAnonymousUser = !isLoggedIn || !isLicensed;
	const skipQuery = skip || isAnonymousUser;

	const { data, error, loading, refetch } = useQuery<
		GetOnboardingState,
		GetOnboardingStateVariables
	>(getOnboardingStateGQL, {
		// @ts-expect-error TODO FIXME the `key` variable is of very weird shape here, needs a subject matter expert to untangle this
		variables: { key },
		skip: skipQuery,
	});

	return {
		data,
		error,
		loading,
		refetch,
	};
};

export const getOnboardingStateThroughClient = async (
	apolloClient: ApolloClient<any>,
	key: string | string[],
) => {
	return await apolloClient.query({
		query: getOnboardingStateGQL,
		variables: {
			key,
		},
	});
};

export const setOnboardingStateThroughClient = async (
	apolloClient: ApolloClient<any>,
	{
		key,
		value,
	}: {
		key: string;
		value: string;
	},
) => {
	return await apolloClient.mutate({
		mutation: setOnboardingStateGQL,
		variables: {
			key,
			value,
		},
		update(cache) {
			cache.writeFragment({
				id: key,
				fragment: gql`
					fragment myKey on OnboardingState {
						key
						value
					}
				`,
				data: {
					key,
					value,
					__typename: 'OnboardingState',
				},
			});
		},
	});
};
