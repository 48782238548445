/* eslint-disable no-restricted-syntax */
import { browserMetrics, metrics } from '@atlassian/browser-metrics';

import { namedRoutes } from '@confluence/named-routes';

export const BM_CONFIGURED_ROUTES = new Set([
	namedRoutes.CREATE_BLOG.name,
	namedRoutes.CREATE_BLOG_ACTION_LEGACY.name,
	namedRoutes.CREATE_PAGE.name,
	namedRoutes.CREATE_PAGE_ACTION_LEGACY.name,
	namedRoutes.EDIT_BLOG.name,
	namedRoutes.EDIT_BLOG_V2.name,
	namedRoutes.EDIT_PAGE.name,
	namedRoutes.EDIT_PAGE_V2.name,
	namedRoutes.EDIT_PAGE_EMBED.name,
	namedRoutes.VIEW_PAGE.name,
	namedRoutes.VIEW_BLOG.name,
	namedRoutes.VIEW_BLOG_DATE_LEGACY.name,
	namedRoutes.SPACE_INDEX.name,
	namedRoutes.SPACE_OVERVIEW.name,
	namedRoutes.TEMPLATE_GALLERY.name,
	namedRoutes.CONTENT_HISTORY.name,
	namedRoutes.ADVANCED_SEARCH.name,
	namedRoutes.HOME.name,
	namedRoutes.SPACE_DIRECTORY.name,
	namedRoutes.SPACE_PAGES.name,
]);

export const isBMPageLoad = (match: { name: string } | null | undefined): boolean => {
	if (!match) {
		return false;
	}
	return BM_CONFIGURED_ROUTES.has(match.name);
};

export const hasBMPageLoadStarted = (): boolean => {
	const currentMetric = browserMetrics.getPageLoadMetric();
	return currentMetric.getData().start !== null;
};

export { getWaterfallTimings } from './getWaterfallTimings';

const { pageLoad, custom, interaction, pageSegmentLoad, concurrent } = metrics;
const concurrentCustom = concurrent.custom;

export { pageLoad as createPageLoadMetric };
export { interaction as createInteractionMetric };
export { pageSegmentLoad as createPageSegmentLoadMetric };

export { custom as createCustomMetric };
export { concurrentCustom as createConcurrentCustomMetric };
