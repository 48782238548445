import {
	SPACE_NAVIGATION_SEGMENT_KEY,
	createPageSegmentLoadMetric,
	createCustomMetric,
	SPACE_NAVIGATION_QUERY_CUSTOM_KEY,
	SPACE_APPS_SEGMENT_KEY,
} from '@confluence/browser-metrics';

import { ssrConfig } from '../ssrConfig';

export const SPACE_NAVIGATION_METRIC = createPageSegmentLoadMetric({
	key: SPACE_NAVIGATION_SEGMENT_KEY,
	ssr: ssrConfig(),
});

export const SPACE_NAVIGATION_QUERY_METRIC = createCustomMetric({
	key: SPACE_NAVIGATION_QUERY_CUSTOM_KEY,
});

export const SPACE_APPS_METRIC = createPageSegmentLoadMetric({
	key: SPACE_APPS_SEGMENT_KEY,
	ssr: ssrConfig(),
});
