import { useRef, useCallback } from 'react';

import type { PageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container';

import { CONTAINER_HOME_ID } from './paths';

export const useScrollTree = ({ view }) => {
	const pageTreeUpdater = useRef<PageTreeStateUpdater>();

	const scrollTree = useCallback(() => {
		if (view === CONTAINER_HOME_ID && pageTreeUpdater.current) {
			pageTreeUpdater.current.scrollToCurrentPage();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [view, pageTreeUpdater.current]);

	return {
		pageTreeUpdater,
		scrollTree,
	};
};
