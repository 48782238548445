import gql from 'graphql-tag';

export const SitePersonalizationQuery = gql`
	query SitePersonalizationQuery($cloudId: String!) @experimental {
		experimentalSitePersonalization(cloudId: $cloudId) {
			attributes {
				name
				value
			}
		}
	}
`;
