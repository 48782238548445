import gql from 'graphql-tag';

export const getOnboardingStateGQL = gql`
	query GetOnboardingState($key: [String]) {
		onboardingState(key: $key) {
			key
			value
		}
	}
`;

export const setOnboardingStateGQL = gql`
	mutation SetOnboardingState($states: [OnboardingStateInput!]!) {
		setOnboardingState(states: $states) {
			key
			value
		}
	}
`;
