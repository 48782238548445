import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { QUICKSTART_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

export interface QuickstartState {
	openTaskId: string;
	isPanelOpen: boolean;
	isDismissed: boolean;
	createSpaceCompleted: boolean;
	createPageCompleted: boolean;
	inviteTeammateCompleted: boolean;
	readConfluenceGuideCompleted: boolean;
	siteTourVideoComplete: boolean;
	sharePageCompleted: boolean;
	integrationCompleted: boolean;
	helpCompleted: boolean;
	collaborateCompleted: boolean;
	homeTourCompleted: boolean;
	spaceModalCompleted: boolean;
	homeModalCompleted: boolean;
	jswXFlowModalCompleted: boolean;
	jswXFlowInvitePeopleModalOpen: boolean;
	jswXflowInvitePeopleNudgeCompleted: boolean;
	jswXflowInvitedEmails?: string;
	spaceTourCompleted: boolean;
	jswConfIntroCompleted: boolean;
	editorTutorialTaskCompleted: boolean;
	qsv2InviteTeammateCompleted: boolean;
	qsv2IntegrationCompleted: boolean;
	migrationsWelcomeCompleted: boolean;
	migrationsNewPageCompleted: boolean;
	migrationsEditAndCustomizeContentCompleted: boolean;
	migrationsCollaborateCompleted: boolean;
	migrationsFindAndOrganizePagesCompleted: boolean;
	openComponentId: string | undefined;
	attributes?: string;
	isLoading: boolean;
	isError: boolean;
	error: Error | null;
	qsv2SpotlightCompleted: boolean;
}

export interface QuickstartContext {
	state: QuickstartState;
}

export interface Quickstart {
	state: QuickstartState;
}

export const QUICKSTART_DEFAULT: Quickstart = {
	state: {
		openTaskId: '',
		isPanelOpen: false,
		isDismissed: true,
		isLoading: true,
		isError: false,
		error: null,
		createSpaceCompleted: true,
		createPageCompleted: false,
		inviteTeammateCompleted: false,
		readConfluenceGuideCompleted: false,
		siteTourVideoComplete: false,
		sharePageCompleted: false,
		integrationCompleted: false,
		helpCompleted: false,
		collaborateCompleted: false,
		homeTourCompleted: false,
		spaceModalCompleted: true,
		homeModalCompleted: true,
		editorTutorialTaskCompleted: false,
		jswXFlowModalCompleted: false,
		jswXFlowInvitePeopleModalOpen: false,
		jswXflowInvitePeopleNudgeCompleted: false,
		spaceTourCompleted: false,
		jswConfIntroCompleted: true,
		qsv2InviteTeammateCompleted: false,
		qsv2IntegrationCompleted: false,
		migrationsWelcomeCompleted: false,
		migrationsNewPageCompleted: false,
		migrationsEditAndCustomizeContentCompleted: false,
		migrationsCollaborateCompleted: false,
		migrationsFindAndOrganizePagesCompleted: false,
		openComponentId: undefined,
		qsv2SpotlightCompleted: true, // start with it being off
	},
};

const successState = {
	qsv2SpotlightCompleted: false,
	spaceModalCompleted: false,
	homeModalCompleted: false,
	jswConfIntroCompleted: false,
};

export const useQuickstartState = ({ skip = false }: { skip?: boolean } = {}) => {
	const {
		data,
		error: onboardingStateError,
		loading: onboardingStateLoading,
	} = useGetOnboardingState(
		Object.values({
			...QUICKSTART_STATE_KEYS,
		}),
		skip,
	);

	if (skip) {
		return {
			...QUICKSTART_DEFAULT.state,
			isLoading: false,
			isError: false,
			error: null,
		};
	}

	return {
		...QUICKSTART_DEFAULT.state,
		...(onboardingStateLoading || Boolean(onboardingStateError) ? {} : successState),
		...deserializeState(data),
		isLoading: Boolean(onboardingStateLoading),
		isError: Boolean(onboardingStateError),
		error: onboardingStateError ?? null,
	};
};
