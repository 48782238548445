import React, { createContext, useContext, useCallback, useMemo } from 'react';

import { useRightSidebarContext } from '@confluence/page-layout-context';

import { NOTES_PANEL_ID, NOTES_SIDEBAR_WIDTH } from './constants';

export type NotesContextType = {
	/**
	 * Opens right sidebar with notes sidebar active
	 * @returns void
	 */
	openSidebar: () => void;
	/**
	 * Closes right sidebar.
	 * @returns void
	 */
	closeSidebar: () => void;
	/**
	 * Opens notes sidebar if not open or active. Closes notes sidebar if notes sidebar is open and active.
	 * @returns void
	 */
	toggleSidebar: () => void;
};

const NotesContextDefaultValue: NotesContextType = {
	openSidebar: () => {},
	closeSidebar: () => {},
	toggleSidebar: () => {},
};

export const NotesContext = createContext<NotesContextType>(NotesContextDefaultValue);

export const useNotesContext = () => useContext<NotesContextType>(NotesContext);

// if no right sidebar context is given, nothing happens... i think
// we may be limited to the scope of the rightsidebar context provider
export const NotesContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const rightSidebarContext = useRightSidebarContext();

	const openSidebar = useCallback(() => {
		rightSidebarContext.open(NOTES_PANEL_ID, NOTES_SIDEBAR_WIDTH);
	}, [rightSidebarContext]);

	const closeSidebar = useCallback(() => {
		rightSidebarContext.closeAll();
	}, [rightSidebarContext]);

	const toggleSidebar = useCallback(() => {
		if (rightSidebarContext.getCurrent()?.panelId === NOTES_PANEL_ID) {
			rightSidebarContext.closeAll();
		} else {
			rightSidebarContext.open(NOTES_PANEL_ID, NOTES_SIDEBAR_WIDTH);
		}
	}, [rightSidebarContext]);

	const notesContextValue = useMemo(
		() => ({
			openSidebar,
			closeSidebar,
			toggleSidebar,
		}),
		[openSidebar, closeSidebar, toggleSidebar],
	);

	return <NotesContext.Provider value={notesContextValue}>{children}</NotesContext.Provider>;
};
