import { useEffect, useLayoutEffect } from 'react';

export enum ActionTiming {
	/**
	 * This will perform the given action at a scheduled time after the
	 * component has painted.
	 *
	 * This is the default behavior.
	 */
	DEFAULT = 'DEFAULT',
	/**
	 * This will perform the given action at the next animation frame (after the
	 * component has been painted).
	 */
	PAINT = 'PAINT',
	/**
	 * This will perform the given action at the moment its render function
	 * fires.
	 *
	 * NOTE: This is called _within_ the render body, making it unpure. The
	 * component is memoized, so it will fire this only once for a given set
	 * of props. Be careful when using this, since it is unsafe.
	 */
	RENDER_BODY = 'RENDER_BODY',
}

export type Props = {
	/**
	 * The action to perform.
	 */
	action: () => void;
	/**
	 * The timing to use when performing an action.
	 */
	timing?: ActionTiming;
};

export const usePerformanceAction = (action: () => void, timing = ActionTiming.DEFAULT) => {
	// The "RENDER_BODY" mark timing
	if (timing === ActionTiming.RENDER_BODY) {
		action();
	}

	// The "PAINT" mark timing
	useLayoutEffect(() => {
		if (timing === ActionTiming.PAINT) {
			const frameRequest = requestAnimationFrame(() => {
				action();
			});

			return () => {
				cancelAnimationFrame(frameRequest);
			};
		}
	}, [action, timing]);

	// The "DEFAULT" mark timing
	useEffect(() => {
		if (timing === ActionTiming.DEFAULT) {
			action();
		}
	}, [action, timing]);
};
