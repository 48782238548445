import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	TopNav: {
		id: 'page-layout.top.nav',
		defaultMessage: 'Confluence navigation',
		description:
			'Accessibility title for skipping to the top navigation section of Confluence. Shows when tabbing first time on a page',
	},
	Banner: {
		id: 'page-layout.banner',
		defaultMessage: 'Banner',
		description:
			'Accessibility title for skipping to the banner section of Confluence. Shows when tabbing first time on a page',
	},
	SideNav: {
		id: 'page-layout.side.nav',
		defaultMessage: 'Side navigation',
		description:
			'Accessibility title for skipping to the Confluence left Side Navigation section. Shows when tabbing first time on a page',
	},
	Main: {
		id: 'page-layout.main',
		defaultMessage: 'Page',
		description:
			'Accessibility title for skipping to the main content section of Confluence. Shows when tabbing first time on a page',
	},
	SkipLinks: {
		id: 'page-layout.skip.links',
		defaultMessage: 'Jump to',
		description:
			'Accessibility title for skipping to the main content section of Confluence. Shows when tabbing first time on a page',
	},
});
