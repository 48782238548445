import isEqual from 'lodash/isEqual';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

type PageTreeStateUpdaterStateType = {
	highlightPagesIds: string[];
	forceFetchPageId: string | null;
	scrollToCurrent: boolean;
	scrollToPageId: string | null;
	editingTitle: {
		contentId: string | null;
		isNewContent?: boolean;
	};
};

export class PageTreeStateUpdater extends Container<PageTreeStateUpdaterStateType> {
	state: PageTreeStateUpdaterStateType = {
		highlightPagesIds: [],
		forceFetchPageId: null,
		scrollToCurrent: false,
		scrollToPageId: null,
		editingTitle: { contentId: null },
	};

	scrollToCurrentPage() {
		void this.setState({ scrollToCurrent: true });
	}

	clearScrollTo() {
		void this.setState({ scrollToCurrent: false });
	}

	setHighlightPagesIds(pageIds: string[]) {
		if (!isEqual(this.state.highlightPagesIds, pageIds)) {
			void this.setState({ highlightPagesIds: pageIds });
		}
	}

	clearHighlightedPages() {
		this.setHighlightPagesIds([]);
	}

	setForceFetchPageId(pageId) {
		void this.setState({ forceFetchPageId: pageId });
	}

	clearForceFetch() {
		this.setForceFetchPageId(null);
	}

	setIsEditingTitleId(contentId) {
		void this.setState({ editingTitle: { contentId } });
	}

	// This is for content types such as folders that do not have a create content url
	// Instead, we allow naming the content inside the page tree immediately after creation
	forceFetchWithInlineRename(forceFetchId, contentId) {
		void this.setState({
			forceFetchPageId: forceFetchId,
			editingTitle: {
				contentId,
				isNewContent: true,
			},
			scrollToPageId: contentId,
		});
	}

	clearScrollToPageId() {
		void this.setState({ scrollToPageId: null });
	}
}
