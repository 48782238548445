import type { FC } from 'react';
import React, { useLayoutEffect, useCallback, useContext } from 'react';

import {
	APP_NAV_CONTAINER_EXPERIENCE,
	APP_NAV_ADMIN_EXPERIENCE,
	APP_NAV_GLOBAL_EXPERIENCE,
	ExperienceTrackerContext,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import {
	ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
	CONTAINER_BLOG_ID,
	PRODUCT_HOME_ID,
	PRODUCT_ADDONS_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';

/**
 * Treat blog nav and space nav as the same experience.
 */
const transformView = (view) => {
	if (view === CONTAINER_BLOG_ID) {
		return CONTAINER_HOME_ID;
	}
	return view;
};

type NavigationExperienceWrapperProps = {
	view?: string;
	children?: React.ReactNode;
	navVersion?: string;
};

export const NavigationExperienceWrapper: FC<NavigationExperienceWrapperProps> = ({
	view,
	children,
	navVersion,
}) => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	const abortExp = useCallback(
		(name: any) =>
			experienceTracker.abort({
				name,
				reason: 'navigation container view changed',
				attributes: {
					navVersion,
				},
			}),
		[experienceTracker, navVersion],
	);

	const startExp = useCallback(
		(name: any) =>
			experienceTracker.start({
				name,
				timeout: ExperienceTimeout.NAVIGATION_LOAD,
				attributes: {
					navVersion,
				},
			}),
		[experienceTracker, navVersion],
	);

	const transformedView = transformView(view);

	useLayoutEffect(() => {
		const containerName = APP_NAV_CONTAINER_EXPERIENCE;
		const adminName = APP_NAV_ADMIN_EXPERIENCE;

		switch (transformedView) {
			case CONTAINER_HOME_ID:
				startExp(containerName);
				abortExp(adminName);
				break;
			case PRODUCT_HOME_ID:
			case PRODUCT_ADDONS_ID:
				abortExp(containerName);
				abortExp(adminName);
				break;
			case ADMIN_HOME_ID:
				startExp(adminName);
				abortExp(containerName);
		}
	}, [transformedView, startExp, abortExp]);

	useLayoutEffect(() => {
		startExp(APP_NAV_GLOBAL_EXPERIENCE);
	}, [startExp]);

	return (
		<ErrorBoundary
			attribution={Attribution.BACKBONE}
			attributes={{
				errorBoundaryId: 'NavigationExperienceWrapper',
			}}
		>
			{children}
		</ErrorBoundary>
	);
};
