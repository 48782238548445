import { useContext } from 'react';

import { usePageSpaceKey } from '@confluence/page-context';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';

export function useSpaceKey(spaceKey?: string): string | null {
	// Space-level data is used to retrieve information about a space's external collaborators
	// in multiple places across the package. Attempt to get spaceKey from a range of sources.

	// If we are in a view-page context, spaceKey data may be available from PageState.
	const [pageContextSpaceKey] = usePageSpaceKey();

	// Otherwise, RoutesContext may contain the information we need
	const { match } = useContext(RoutesContext);
	const routesContextSpaceKey = match?.params?.spaceKey;

	// Now that our context hooks have executed, we can return if we have a match.
	// Return statements are separated out for optimum readability.
	if (spaceKey) {
		return spaceKey;
	}
	if (pageContextSpaceKey) {
		return pageContextSpaceKey;
	}
	if (routesContextSpaceKey) {
		return routesContextSpaceKey;
	}

	// Legacy server-rendered UI pages do not have the "match" object in RoutesContext, but
	// they can contain space information in the <meta> data tag.
	const documentMetaSpaceKey =
		typeof document !== 'undefined'
			? (document.querySelector('meta#confluence-space-key') as HTMLMetaElement)?.content
			: null;

	return documentMetaSpaceKey ?? null;
}
