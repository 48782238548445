import {
	createContainer,
	createStore,
	createActionsHook,
	createStateHook,
} from 'react-sweet-state';
import type { FC } from 'react';
import React from 'react';

export type InProductHelpContextType = {
	articleId?: string;
	routeName?: string;
	routeGroup?: string;
};

// testing purposes
export const getContextValue = (
	articleId: string,
	setArticleId: (articleId: string) => void,
	routeName: string,
	setRouteName: (routeName: string) => void,
	routeGroup: string,
	setRouteGroup: (routeGroup: string) => void,
) => ({
	getArticleId: () => articleId,
	setArticleId,
	getRouteName: () => routeName,
	setRouteName,
	getRouteGroup: () => routeGroup,
	setRouteGroup,
});

type State = {
	articleId: string;
	routeName: string;
	routeGroup: string;
};

const initialInProductHelpState: State = {
	articleId: '',
	routeName: '',
	routeGroup: '',
};

const inProductHelpActions = {
	setArticleId:
		(articleId: string) =>
		({ setState }) => {
			setState({ articleId });
		},
	setRouteName:
		(routeName: string) =>
		({ setState }) => {
			setState({ routeName });
		},
	setRouteGroup:
		(routeGroup: string) =>
		({ setState }) => {
			setState({ routeGroup });
		},
};

const Store = createStore({
	initialState: {
		...initialInProductHelpState,
	},
	actions: inProductHelpActions,
	name: 'inProductHelpContext',
});

export const useInProductHelpActions = createActionsHook(Store);

export const useInProductHelpArticleId = createStateHook(Store, {
	selector: (state) => state.articleId,
});

export const useInProductHelpRouteName = createStateHook(Store, {
	selector: (state) => state.routeName,
});

export const useInProductHelpRouteGroup = createStateHook(Store, {
	selector: (state) => state.routeGroup,
});

export const InProductHelpProviderContainer = createContainer(Store, {
	onInit:
		() =>
		({ setState }, productState: InProductHelpContextType) => {
			setState(productState);
		},
	onUpdate:
		() =>
		({ setState }, productState: InProductHelpContextType) => {
			setState(productState);
		},
});

export const InProductHelpProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <InProductHelpProviderContainer>{children}</InProductHelpProviderContainer>;
};
