import { useQuery } from 'react-apollo';
import type { ApolloError, WatchQueryFetchPolicy } from 'apollo-client';
import type { GraphQLError } from 'graphql';

import { useSessionData } from '@confluence/session-data';
import { isUnauthorizedError, isStatusCodeError } from '@confluence/error-boundary';

import { SitePersonalizationQuery } from './SitePersonalizationQuery.experimentalgraphql';
import type {
	SitePersonalizationQuery as SitePersonalizationQueryData,
	SitePersonalizationQuery_experimentalSitePersonalization_attributes as SitePersonalizationAttributes,
	SitePersonalizationQueryVariables,
} from './__types__/SitePersonalizationQuery';
export let skipQueryIfUnauthorized = false;

export const useSitePersonalizationQuery = ({
	cloudId,
	skip,
	fetchPolicy,
}: {
	cloudId: string;
	skip?: boolean;
	fetchPolicy?: WatchQueryFetchPolicy;
}) => {
	const { isLoggedIn, isLicensed } = useSessionData();

	const skipOptions =
		skip || !isLoggedIn || !isLicensed || skipQueryIfUnauthorized || Boolean(process.env.REACT_SSR); // SitePersonalizationQuery isn't preloaded and can be skipped during SSR to avoid unnecessary requests

	const { data, loading, error } = useQuery<
		SitePersonalizationQueryData,
		SitePersonalizationQueryVariables
	>(SitePersonalizationQuery, {
		variables: {
			cloudId,
		},
		fetchPolicy: fetchPolicy || 'cache-first',
		skip: skipOptions,
		onError(apolloError: ApolloError) {
			skipQueryIfUnauthorized = apolloError?.graphQLErrors?.some(
				(graphQLError: GraphQLError) =>
					graphQLError?.path?.includes('experimentalSitePersonalization') &&
					(graphQLError?.message?.includes('/personalization Received status 401') ||
						graphQLError?.message?.includes('/personalization Received status 403') ||
						// Prepare for the future when experimentalSitePersonalization
						// stops replacing the original error or, most likely,
						// experimental GraphQL is moved from the client to the server:
						isStatusCodeError(graphQLError, '401') ||
						isUnauthorizedError(graphQLError)),
			);
		},
	});
	const attributes: SitePersonalizationAttributes[] =
		data?.experimentalSitePersonalization?.attributes || [];
	return {
		loading,
		error,
		attributes,
	};
};
