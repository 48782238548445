import { concurrent } from './concurrent';
import { custom, interaction, pageLoad, pageSegmentLoad } from './core';

export const metrics = {
	pageLoad,
	pageSegmentLoad,
	interaction,
	custom,
	concurrent,
};
