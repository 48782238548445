/**
 * Matches patterns that are either allowlisted or blocklisted, and returns whether or not it should be allowed
 * to show the quickstart feature
 */
export const isNameAllowed = (name: string | undefined): boolean => {
	const allowlistPattern = [/VIEW_BLOG_DATE_LEGACY/];
	const blocklistPatterns = [
		/EMBED_VIEW/,
		/WHITEBOARD_HOME/,
		/WHITEBOARD_BOARD/,
		/LEGACY/,
		/ADMIN/,
		// this is the legacy calendar - different from "MY_CALENDAR" name
		/^CALENDAR$/,
	];

	if (!name) {
		return false;
	}
	const isAllowlistPath =
		blocklistPatterns.every((pattern) => !pattern.test(name)) ||
		allowlistPattern.some((pattern) => pattern.test(name));
	return isAllowlistPath;
};
