import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export const useWindowSize = () => {
	const isClient = typeof window === 'object';

	const getSize = () => {
		return {
			width: isClient ? window.innerWidth : 0,
			height: isClient ? window.innerHeight : 0,
		};
	};

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		const handleResize = debounce(() => {
			setWindowSize(getSize());
		}, 200);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
			handleResize.cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Empty array ensures that effect is only run on mount and unmount

	return windowSize;
};
