import type { ApolloError } from 'apollo-client';

import { containsNoNetworkError } from '@confluence/network';
import { isUnauthorizedError } from '@confluence/error-boundary';

export function isExpectedError(error: ApolloError) {
	const isUnauthorized =
		error?.graphQLErrors?.[0]?.message === 'unauthorized' || isUnauthorizedError(error);

	return isUnauthorized || containsNoNetworkError(error);
}
