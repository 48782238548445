import React, { useMemo } from 'react';

import {
	Query as ApolloQuery,
	type OperationVariables,
	type QueryComponentOptions,
} from 'react-apollo';

import getOperationName from '../utils/get-operation-name';

import { withUFOHold } from './withUFOHold';

export function Query<TData = any, TVariables extends OperationVariables = OperationVariables>({
	children,
	...props
}: QueryComponentOptions<TData, TVariables>) {
	const operationName = useMemo(() => getOperationName(props.query), [props.query]);

	return (
		<ApolloQuery<TData, TVariables> {...props}>
			{withUFOHold('query', operationName, children)}
		</ApolloQuery>
	);
}
